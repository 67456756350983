const paymentMethods = [
  {
    location: 'all',
    width: 39,
    height: 12,
    alt: 'footer:payment_methods.alts.visa',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/visa.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/visa-color.svg`,
  },
  {
    location: 'all',
    width: 31,
    height: 24,
    alt: 'footer:payment_methods.alts.mastercard',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/mastercard.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/mastercard-color.svg`,
  },
  {
    location: 'all',
    width: 47,
    height: 12,
    alt: 'footer:payment_methods.alts.paypal',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/paypal.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/paypal-color.svg`,
  },
  {
    location: 'all',
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.bank-transfer',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/BTR.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/BTR-color.svg`,
  },
  {
    location: [1, 2, 3, 4, 8, 11, 13, 14, 23, 25, 26],
    width: 40,
    height: 20,
    alt: 'footer:payment_methods.alts.alma',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/alma.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/alma-color.svg`,
  },
  {
    location: [1, 2, 3],
    width: 25,
    height: 24,
    alt: 'footer:payment_methods.alts.mb',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/MB.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/MB-color.svg`,
  },
  {
    location: [1, 2, 3],
    width: 49,
    height: 24,
    alt: 'footer:payment_methods.alts.mbway',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/MBWAY.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/MBWAY-color.svg`,
  },
  {
    location: [25],
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.bct',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/bancontact.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/bancontact-color.svg`,
  },
  {
    location: [26],
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.eps',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/eps.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/eps-color.svg`,
  },
  {
    location: [8],
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.idl',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/ideal.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/ideal-color.svg`,
  },
  {
    location: [13, 25, 26],
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.sof',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/sofort.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/sofort-color.svg`,
  },
  {
    location: [11],
    width: 47,
    height: 20,
    alt: 'footer:payment_methods.alts.cb',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/cb.svg`,
    srcColor: `${process.env.PUBLIC_PREFIX}/svg/icons/cb-color.svg`,
  },
];

const useGetPaymentMethods = (shippingLocation: string | number | null) => {
  if (!shippingLocation) return paymentMethods;

  const shippingLocationId =
    typeof shippingLocation === 'string'
      ? parseInt(shippingLocation)
      : shippingLocation;

  return paymentMethods.filter(
    (pm) =>
      pm.location === 'all' || pm.location.includes(shippingLocationId as never)
  );
};

export default useGetPaymentMethods;
