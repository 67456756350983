import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent } from 'react';

const ItemWithImage: FunctionComponent<{
  label: string;
  icon: string;
  flagPosition?: 'left' | 'right';
}> = ({ icon, label, flagPosition = 'right' }) => (
  <div
    className={classNames(
      'grid',
      {
        'grid-cols-[1fr,auto]': flagPosition === 'right',
        'grid-cols-[auto,1fr]': flagPosition === 'left',
      },
      'gap-4',
      'items-center'
    )}
  >
    <div className={classNames('flex-1', 'truncate')}>{label}</div>
    <div
      className={classNames('rounded-full shadow-md', {
        '-order-1': flagPosition === 'left',
      })}
    >
      <Image src={icon} width={24} height={24} alt={label} />
    </div>
  </div>
);

export default ItemWithImage;
