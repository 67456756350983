import { fechFromBPartsApi } from '../utils';

export const setShippingLocation = ({
  locale,
  shipping_location,
  csrftoken,
}: {
  locale: string;
  shipping_location: string;
  csrftoken: string;
}): Promise<{} | { value: string }> =>
  fechFromBPartsApi(`/${locale}/shop/shipping_locations/change`, {
    method: 'PUT',
    headers: new Headers([
      ['Content-Type', 'application/json'],
      ['X-CSRFToken', csrftoken],
    ]),
    credentials: 'include',
    body: JSON.stringify({ value: shipping_location }),
  }).then(async (value) => {
    if (value.status >= 400 && value.status < 600) {
      const json = await value.json();
      throw { ...json, status: value.status };
    }

    return {};
  });
