import Cookies from 'js-cookie';
import { resolveHref } from 'next/dist/client/resolve-href';
import { useRouter } from 'next/router';
import getT from 'next-translate/getT';

import correlationTypes from '../components/modules/language-selector/correlationTypes';
import translateUrl from '../components/modules/language-selector/translate-url';
import { loadShippingLocations } from '../components/modules/shipping-selector';
import { originalRoutes } from '../hooks/use-link-props';

type redirectToNewShippingLocationParams = {
  locations: Awaited<ReturnType<typeof loadShippingLocations>>;
  newShippingLocation: string | number;
  router: ReturnType<typeof useRouter>;
  isBparts: boolean;
};

export const redirectToNewShippingLocation = async ({
  locations,
  newShippingLocation,
  router,
  isBparts,
}: redirectToNewShippingLocationParams) => {
  const isUK =
    locations.find((location) => {
      return location.value.toString() == newShippingLocation;
    })?.country_code === 'GB';

  if (isUK) {
    if (window.location.hostname.includes('.co.uk')) {
      // se novo shipping é uk e donmínio é .co.uk => reload
      window.location.replace(window.location.toString());
    } else {
      // se novo shipping é uk e domínio é .com => push
      const new_url = await translateUrl({
        asPath: router.asPath,
        csrftoken: Cookies.get('csrftoken') || '',
        language: 'en', // Uk only has en
        locale: router.locale as string,
        locales: router.locales as string[],
        pathname: router.pathname,
        query: router.query,
      }).then(async (data) => {
        if (data[0]?.old_url_path) {
          // if the setLanguage fetch return an old_url_path we need to redirect
          // because it is only returned by the API when we send the url_path
          window.location = data[0]?.old_url_path;
        } else {
          // get the current route key of routes json, from pathname
          const route_key = Object.keys(originalRoutes).find(
            (k) => originalRoutes[k] === router.pathname
          );

          // get translated slugs
          // part need to be hacked, remember? part === subCategory
          let translatedSlugs = {};
          correlationTypes[router.pathname]?.map((q) => {
            const slug = data[1]['slugs'].find((s) => s.slug_type === q.target);
            if (!!slug) {
              translatedSlugs = {
                ...translatedSlugs,
                [q.original]: slug ? slug.slug_trans : undefined,
              };
            }
          });

          const newQuery = {
            ...router.query, // <- keep all parameters
            ...translatedSlugs, // <- add translated slugs
          };

          // setup a new t function for new language
          const t2 = await getT('en', 'routes');

          return resolveHref(
            router,
            {
              pathname: t2(route_key), // force translate to new language
              query: { ...newQuery, shipping_location: newShippingLocation },
            },
            true
          )[1] as string;
        }
      });

      router.push(
        isBparts
          ? process.env.NEXT_PUBLIC_HOST?.includes('dev')
            ? `https://dev.b-parts.co.uk${new_url}`
            : `https://www.b-parts.co.uk${new_url}`
          : `${process.env.NEXT_PUBLIC_HOST}${new_url}`
      );
    }
  } else {
    if (window.location.hostname.includes('.co.uk')) {
      // se novo shipping não é uk e domínio é .co.uk => redirect
      /*router.push(
        `${process.env.NEXT_PUBLIC_HOST}?shipping_location=${newShippingLocation}`
      );*/
      window.location.replace(
        `${
          process.env.NEXT_PUBLIC_HOST?.includes('dev')
            ? 'https://dev.b-parts.com'
            : 'https://b-parts.com'
        }?shipping_location=${newShippingLocation}`
      );
    } else {
      // se novo shipping não é uk e domínio é .com => reload
      window.location.replace(window.location.toString());
    }
  }
};
