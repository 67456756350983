import { ParsedUrlQuery } from 'querystring';

import { setLanguage } from '../../../services/language/set';
import { translateSlugs } from '../../../services/language/slug_translation';

import correlationTypes from './correlationTypes';

export default async function translateUrl({
  locales,
  locale,
  language,
  csrftoken,
  asPath,
  pathname,
  query,
  changeLocale = true,
}: {
  locales: string[];
  locale: string;
  language: string;
  csrftoken: string;
  asPath: string;
  pathname: string;
  query: ParsedUrlQuery;
  changeLocale?: boolean;
}) {
  let url_path = '';
  let slugsToTranslate = null;

  // verify if the target language is available in this website
  if (!locales.includes(language)) {
    // if language is not available, the url_path will be used
    // in setLanguage API call to get the link to this page
    // in the old website
    url_path = asPath;
  } else {
    // get slugs that need translation to new language
    slugsToTranslate = [];
    correlationTypes[pathname]?.map((q) => {
      if (query[q.original]) {
        slugsToTranslate.push({
          slug_type: q.target,
          slug_origin: query[q.original],
        });
      }
    });
  }

  const transSlugs = url_path
    ? null
    : await translateSlugs({
        lang_origin: locale,
        lang_trans: language,
        slugs: slugsToTranslate,
        csrftoken,
      });

  const languageSet = changeLocale
    ? await setLanguage({
        locale,
        language,
        ...(url_path && { url_path }), // <- if we need old website url
        csrftoken,
      })
    : null;

  return [languageSet, transSlugs];
}
