import cx from 'classnames';
import { useAtom } from 'jotai';
import { ComponentProps, forwardRef, memo } from 'react';

import { navigationOpen } from '../../../../state/navigation';
import { useDisableFocus } from '../../../providers/disable-focus';

type TVariants = 'DEFAULT' | 'PRIMARY' | 'BUTTON-PRIMARY';

type AnchorProps = {
  as?: 'a';
  disableFocusOnMenu?: boolean;
  variant?: TVariants;
} & ComponentProps<'a'>;

type ButtonProps = {
  as?: 'button';
  disableFocusOnMenu?: boolean;
  variant?: TVariants;
} & ComponentProps<'button'>;

const getVariant = (variant: TVariants) => {
  switch (variant) {
    case 'BUTTON-PRIMARY':
      return cx(
        'inline-block',
        'transition-colors',
        'duration-200',
        'leading-4',
        'p-4',
        'font-bold',
        'bg-brand-logo',
        'text-white',
        'hover:bg-brand-40',
        'hover:text-neutral-80',
        'focus:text-neutral-80',
        'focus:bg-brand-40'
      );
    case 'PRIMARY':
      return cx('text-brand-100', 'font-bold', 'text-2xl', 'leading-8');
    default:
      return cx();
  }
};

const TextLink = memo(
  forwardRef<HTMLAnchorElement | HTMLButtonElement, AnchorProps | ButtonProps>(
    (
      {
        as: Component = 'a',
        children,
        disableFocusOnMenu,
        variant = 'PRIMARY',
        ...props
      },
      ref
    ) => {
      const disableFocus = useDisableFocus();
      const [menuOpen] = useAtom(navigationOpen);

      const hasNoFocus = disableFocus || (disableFocusOnMenu && menuOpen);

      return (
        <Component
          ref={ref /* TODO */}
          tabIndex={hasNoFocus ? -1 : 0}
          className={cx('cursor-pointer', 'relative', '', getVariant(variant))}
          {...props}
        >
          {children}
        </Component>
      );
    }
  )
);

TextLink.displayName = 'TextLink';

export default TextLink;
